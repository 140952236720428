<template>
  <div class="ProductList">
    <div class="rowBox">
      <van-row>
        <van-col span="12" v-for="(item, index) in productList" :key="index">
          <div class="productBox">
            <div
              class="product"
              @click="getProductDetails(item.ProductNumber, item.ShopId)"
            >
              <figure class="productImg">
                <van-image
                  class="productIcon"
                  :src="imgUrlFilter(item.ProductPhoto)"
                >
                  <template v-slot:error>
                    <img class="ImgIcon" src="~assets/img/other/empty.gif" />
                  </template>
                </van-image>

                <!-- <img class="ImgIcon" :src="imgUrlFilter(item.ProductPhoto)" /> -->
              </figure>
              <div class="productTitle">
                <div class="discount" v-if="item.IsCouponProduct">
                  {{ $t.discount }}
                </div>
                <div class="brand" v-if="item.IsBrandProduct">
                  {{ $t.brand }}
                </div>
                <div class="attestation" v-if="item.IsAttestationProduct">
                  {{ $t.attestation }}
                </div>
                <div class="name">{{ item.ProductName }}</div>
              </div>
              <section class="info">
                <p class="unit">$</p>
                <!-- <p class="num">
                  {{
                    item.MinPrice !== item.MaxPrice
                      ? Math.round(item.MinPrice * 100) / 100 + $t.up
                      : Math.round(item.MinPrice * 100) / 100
                  }}
                </p>-->
                <p class="num">
                  {{ Math.round(item.MinPrice * 100) / 100 }}
                </p>
                <!-- <p class="sale">{{ item.Sales }} {{ $t.homeList.Sold }}</p> -->
              </section>
            </div>
          </div>
        </van-col>
      </van-row>
    </div>
    <placeholder v-if="isPlaceholder"></placeholder>
  </div>
</template>
<script>
import Vue from "vue";
import { Row, Col, Image } from "vant";
import Placeholder from "@components/common/Placeholder";
Vue.use(Row).use(Col).use(Image);

export default {
  name: "ProductList",
  props: ["productList", "isPlaceholder"],
  components: {
    Placeholder,
  },
  data() {
    return {};
  },
  methods: {
    imgUrlFilter(value) {
      // console.log(value.split("|")[0]);
      return this.$api.formatImageUrl(value.split("|")[0]);
    },
    // 跳转详情页
    getProductDetails(ProductNumber, ShopId) {
      this.$commonEnv.commonAction(this.$commonEnv.actionType.routerPush, {
        isParams: true,
        path: "details",
        name: "details",
        query: [
          {
            name: "ProductNumber",
            value: ProductNumber,
          },
          {
            name: "ShopId",
            value: ShopId,
          },
        ],
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@mixin publicProduct {
  width: 97%;
  border-radius: 10px;
  box-shadow: 0 0 0px 1px #ece7e7;
  background-color: #ffffff;
  margin-right: 3%;
}
@mixin productInfo {
  padding: 0;
  margin: 0;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ProductList {
  .rowBox {
    width: 92%;
    margin: 0 auto;
    ::v-deep .van-row {
      .van-col {
        margin-top: 3vw;
        &:nth-child(even) {
          .productBox {
            .product {
              // background-color: red;
              @include publicProduct;
              margin-left: 3%;
            }
          }
        }
        &:nth-child(odd) {
          .productBox {
            .product {
              // background-color: red;
              margin: 0;
              @include publicProduct;
              margin-right: 3%;
            }
          }
        }
      }
    }
    .productBox {
      width: 100%;
      position: relative;
      background-color: #f6f6f6;
      .productImg {
        margin: 0;
        padding: 0;
        width: 100%;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        .productIcon {
          width: 100%;
          height: 44.4vw;
          object-fit: cover;
          -webkit-object-fit: cover;
          border-top-left-radius: 10px;
          border-top-right-radius: 10px;
          .ImgIcon {
            width: 100%;
            height: 100%;
            object-fit: cover;
            -webkit-object-fit: cover;
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
          }
          ::v-deep .van-image__img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            -webkit-object-fit: cover;
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
          }
          ::v-deep .van-image__error {
            width: 100%;
            height: 100%;
            object-fit: cover;
            -webkit-object-fit: cover;
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
          }
        }
      }
      .productTitle {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        word-break: break-all;
        text-overflow: ellipsis;
        white-space: normal;
        color: #000000;
        font-size: 12px;
        font-family: Helvetica;
        font-weight: 400;
        height: 45px;
        margin: 10px;
        .discount {
          vertical-align: middle;
          display: inline-block;
          background: red;
          color: white;
          padding: 0px 2px;
          border-radius: 2px;
          font-size: 10px;
          margin-right: 5px;
        }
        .brand {
          vertical-align: middle;
          display: inline-block;
          background: #e6a23c;
          color: white;
          padding: 0px 2px;
          border-radius: 2px;
          font-size: 10px;
          margin-right: 5px;
        }
        .attestation {
          vertical-align: middle;
          display: inline-block;
          background: #22b196;
          color: white;
          padding: 0px 2px;
          border-radius: 2px;
          font-size: 10px;
          margin-right: 5px;
        }
        .name {
          display: inline-block;
          vertical-align: middle;
        }
      }
      .info {
        width: 90%;
        margin: 0 auto;
        padding-bottom: 10px;
        display: flex;
        display: -webkit-flex;
        align-items: center;
        -webkit-align-items: center;
        height: 20px;
        line-height: 20px;
        .unit {
          @include productInfo;
          font-size: 12px;
          color: #ff5852;
          width: 8%;
          font-weight: bold;
        }
        .num {
          @include productInfo;
          color: #ff5852;
          font-weight: bold;
          text-align: left;
          //font-size: 20px;
          font-size: 18.5px;
          width: 52%;
        }
        .sale {
          @include productInfo;
          color: #a7a7a7;
          text-align: right;
          font-size: 12px;
          width: 40%;
        }
      }
    }
  }
}
</style>
